import { FormEvent, useRef } from 'react';
import { useRouter } from 'next/router';
import { ListingSearchPanel } from '@/types';
import SearchFilters from './SearchFilters';
import { createListingFilters } from '@/lib/listingUtils';
import { getFormFilters } from './utils';

// Static panel renders a classic html form, which looks the same as dynamicPanel. The functionality is different, static.

const StaticSearchPanel = ({ propertyTypes }: ListingSearchPanel) => {
  const { query, push } = useRouter();
  const form = useRef<HTMLFormElement>(null);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // The reason we need to use custom onSubmit instead of default GET, is because we need to handle the values
    const values = getFormFilters(e.target as HTMLFormElement);

    push({
      pathname: '/osta',
      query: {
        ...query,
        ...values,
      },
    }).catch((err) => {
      console.warn(err);
    });
  };

  const getCurrentFilters = () => {
    if (!form?.current) {
      return null;
    }
    return getFormFilters(form.current);
  };

  return (
    <SearchFilters
      propertyTypes={propertyTypes}
      currentFilters={createListingFilters(
        query,
      )} /* Only a "nice/fun" to have, but no real value */
      getCurrentFilters={getCurrentFilters}
    >
      {(formChildren) => (
        <form onSubmit={onSubmit} ref={form}>
          {formChildren}
        </form>
      )}
    </SearchFilters>
  );
};

export default StaticSearchPanel;
